export default {
  spacing: {
    px: '1px',
    0: '0',
    1: '0.25rem', // 4px
    2: '0.5rem', // 8px
    3: '0.75rem', // 12px
    4: '1rem', // 16px
    6: '1.5rem', // 24px
    8: '2rem', // 32px
    12: '3rem', // 48px
    16: '4rem', // 64px
    24: '6rem', // 96px
    32: '8rem', // 128px
    48: '12rem', // 192px
    64: '16rem', // 256px
    96: '24rem', // 384px
    128: '32rem', // 512px
    160: '40rem', // 640px
    192: '48rem', // 768px
  },
  space: [
    0,
    1,
    4,
    8,
    12,
    16,
    24,
    32,
    48,
    64,
    96,
    128,
    192,
    256,
    384,
    512,
    640,
    768,
  ],
  fonts: {
    body:
      'Larsseit, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
    heading:
      'Larsseit, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
    monospace: '"SF Mono", Menlo, Monaco, "Courier New", monospace',
  },
  fontSizes: [12, 14, 16, 18, 20, 24, 30, 36, 48, 60, 72, 96],
  fontWeights: {
    body: 400,
    heading: 600,
    bold: 600,
  },
  lineHeights: {
    body: 1.5,
    headings: 1.125,
  },
  letterSpacings: {
    body: 'normal',
    caps: '0.2em',
  },
  colors: {
    black: '#000000',
    white: '#ffffff',
    grey_1000: '#1f2933',
    grey_900: '#323f4b',
    grey_800: '#3e4c59',
    grey_700: '#52606d',
    grey_600: '#616e7c',
    grey_500: '#7b8794',
    grey_400: '#9aa5b1',
    grey_300: '#cbd2d9',
    grey_200: '#e4e7eb',
    grey_100: '#f5f7fa',
    blue_1000: '#035388',
    blue_900: '#0b69a3',
    blue_800: '#127fbf',
    blue_700: '#1992d4',
    blue_600: '#2bb0ed',
    blue_500: '#40c3f7',
    blue_400: '#5ed0fa',
    blue_300: '#81defd',
    blue_200: '#b3ecff',
    blue_100: '#e3f8ff',
    pink_1000: '#620042',
    pink_900: '#870557',
    pink_800: '#a30664',
    pink_700: '#bc0a6f',
    pink_600: '#da127d',
    pink_500: '#e8368f',
    pink_400: '#f364a2',
    pink_300: '#ff8cba',
    pink_200: '#ffb8d2',
    red_600: '#e12d39',
    text: '#323f4b',
    background: '#fff',
    primary: '#1f2933',
    secondary: '#e4e7eb',
    tertiary: '#f5f7fa',
    muted: '#f5f7fa',
    highlight: '#52606d',
  },
  sizes: {
    maxWidth: '79rem',
    maxWidthCentered: '40rem',
  },
  responsive: {
    small: '35em',
    medium: '50em',
    large: '70em',
  },
  styles: {
    root: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body',
    },
    h1: {
      color: 'primary',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 5,
    },
    h2: {
      color: 'primary',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 4,
    },
    h3: {
      color: 'primary',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 3,
    },
    h4: {
      color: 'primary',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 2,
    },
    h5: {
      color: 'primary',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 1,
    },
    h6: {
      color: 'primary',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 0,
    },
    p: {
      color: 'text',
      fontFamily: 'body',
      fontWeight: 'body',
      lineHeight: 'body',
      fontSize: 3,
    },
    a: {
      color: 'primary',
    },
    pre: {
      fontFamily: 'monospace',
      overflowX: 'auto',
      code: {
        color: 'inherit',
      },
    },
    code: {
      fontFamily: 'monospace',
      fontSize: 'inherit',
    },
    table: {
      width: '100%',
      borderCollapse: 'separate',
      borderSpacing: 0,
    },
    th: {
      textAlign: 'left',
      borderBottomStyle: 'solid',
    },
    td: {
      textAlign: 'left',
      borderBottomStyle: 'solid',
    },
    img: {
      maxWidth: '100%',
    },
  },
}
